// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file template/v1/template_api.proto (package template.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateTemplateRequest, CreateTemplateResponse, DeleteTemplateRequest, DeleteTemplateResponse, GetTemplateRequest, GetTemplateResponse, ListTemplatesRequest, ListTemplatesResponse } from "./template_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * TemplateAPI service
 *
 * @generated from service template.v1.TemplateAPI
 */
export const TemplateAPI = {
  typeName: "template.v1.TemplateAPI",
  methods: {
    /**
     * @generated from rpc template.v1.TemplateAPI.ListTemplates
     */
    listTemplates: {
      name: "ListTemplates",
      I: ListTemplatesRequest,
      O: ListTemplatesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc template.v1.TemplateAPI.GetTemplate
     */
    getTemplate: {
      name: "GetTemplate",
      I: GetTemplateRequest,
      O: GetTemplateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc template.v1.TemplateAPI.CreateTemplate
     */
    createTemplate: {
      name: "CreateTemplate",
      I: CreateTemplateRequest,
      O: CreateTemplateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc template.v1.TemplateAPI.DeleteTemplate
     */
    deleteTemplate: {
      name: "DeleteTemplate",
      I: DeleteTemplateRequest,
      O: DeleteTemplateResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

