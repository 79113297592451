// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file affiliate/v1/affiliate_api.proto (package affiliate.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddAffiliateRequest, AddAffiliateResponse, ListAffiliatesRequest, ListAffiliatesResponse, RemoveAffiliateRequest, RemoveAffiliateResponse } from "./affiliate_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * AffiliateAPI service
 *
 * @generated from service affiliate.v1.AffiliateAPI
 */
export const AffiliateAPI = {
  typeName: "affiliate.v1.AffiliateAPI",
  methods: {
    /**
     * @generated from rpc affiliate.v1.AffiliateAPI.AddAffiliate
     */
    addAffiliate: {
      name: "AddAffiliate",
      I: AddAffiliateRequest,
      O: AddAffiliateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc affiliate.v1.AffiliateAPI.RemoveAffiliate
     */
    removeAffiliate: {
      name: "RemoveAffiliate",
      I: RemoveAffiliateRequest,
      O: RemoveAffiliateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc affiliate.v1.AffiliateAPI.ListAffiliates
     */
    listAffiliates: {
      name: "ListAffiliates",
      I: ListAffiliatesRequest,
      O: ListAffiliatesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

