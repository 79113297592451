// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file template/v1/template_api.proto (package template.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { SortDirection } from "../../base/v1/sort_pb.js";
import { ListTemplate, Template } from "./template_pb.js";

/**
 * @generated from message template.v1.CreateTemplateRequest
 */
export const CreateTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "template.v1.CreateTemplateRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "preview_image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message template.v1.CreateTemplateResponse
 */
export const CreateTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "template.v1.CreateTemplateResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message template.v1.ListTemplatesRequest
 */
export const ListTemplatesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "template.v1.ListTemplatesRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ],
);

/**
 * @generated from message template.v1.ListTemplatesResponse
 */
export const ListTemplatesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "template.v1.ListTemplatesResponse",
  () => [
    { no: 1, name: "templates", kind: "message", T: ListTemplate, repeated: true },
  ],
);

/**
 * @generated from message template.v1.GetTemplateRequest
 */
export const GetTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "template.v1.GetTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message template.v1.GetTemplateResponse
 */
export const GetTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "template.v1.GetTemplateResponse",
  () => [
    { no: 1, name: "template", kind: "message", T: Template },
  ],
);

/**
 * @generated from message template.v1.DeleteTemplateRequest
 */
export const DeleteTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "template.v1.DeleteTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delete_files", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message template.v1.DeleteTemplateResponse
 */
export const DeleteTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "template.v1.DeleteTemplateResponse",
  [],
);

