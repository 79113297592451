// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file diary/v1/diary_api.proto (package diary.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateDiaryPageRequest, CreateDiaryPageResponse, GetDiaryPageRequest, GetDiaryPageResponse, GetDiarySettingRequest, GetDiarySettingResponse, ListDiaryPagesRequest, ListDiaryPagesResponse, ListStudentDiaryPagesRequest, ListStudentDiaryPagesResponse, ListStudentsRequest, ListStudentsResponse, ShareDiaryRequest, ShareDiaryResponse, UnShareDiaryRequest, UnShareDiaryResponse, UpdateDiaryPageRequest, UpdateDiaryPageResponse } from "./diary_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * DiaryAPI service
 *
 * @generated from service diary.v1.DiaryAPI
 */
export const DiaryAPI = {
  typeName: "diary.v1.DiaryAPI",
  methods: {
    /**
     * @generated from rpc diary.v1.DiaryAPI.CreateDiaryPage
     */
    createDiaryPage: {
      name: "CreateDiaryPage",
      I: CreateDiaryPageRequest,
      O: CreateDiaryPageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc diary.v1.DiaryAPI.UpdateDiaryPage
     */
    updateDiaryPage: {
      name: "UpdateDiaryPage",
      I: UpdateDiaryPageRequest,
      O: UpdateDiaryPageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc diary.v1.DiaryAPI.ListDiaryPages
     */
    listDiaryPages: {
      name: "ListDiaryPages",
      I: ListDiaryPagesRequest,
      O: ListDiaryPagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc diary.v1.DiaryAPI.ListStudents
     */
    listStudents: {
      name: "ListStudents",
      I: ListStudentsRequest,
      O: ListStudentsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc diary.v1.DiaryAPI.ListStudentDiaryPages
     */
    listStudentDiaryPages: {
      name: "ListStudentDiaryPages",
      I: ListStudentDiaryPagesRequest,
      O: ListStudentDiaryPagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc diary.v1.DiaryAPI.GetDiaryPage
     */
    getDiaryPage: {
      name: "GetDiaryPage",
      I: GetDiaryPageRequest,
      O: GetDiaryPageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc diary.v1.DiaryAPI.GetDiarySetting
     */
    getDiarySetting: {
      name: "GetDiarySetting",
      I: GetDiarySettingRequest,
      O: GetDiarySettingResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc diary.v1.DiaryAPI.ShareDiary
     */
    shareDiary: {
      name: "ShareDiary",
      I: ShareDiaryRequest,
      O: ShareDiaryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc diary.v1.DiaryAPI.UnShareDiary
     */
    unShareDiary: {
      name: "UnShareDiary",
      I: UnShareDiaryRequest,
      O: UnShareDiaryResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

