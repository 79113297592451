import {
  Box,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  useToast,
  useSteps,
  SimpleGrid,
  Text,
  InputGroup,
  InputLeftElement,
  Center,
  HStack,
  PinInput,
  PinInputField,
  Icon,
  InputRightElement,
  Flex,
  Link,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@connectrpc/connect-query";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  completeRegistration,
  resendRegistrationOTP,
  startRegistration,
  verifyRegistrationOTP,
} from "proto/register/v1/register-RegisterAPI_connectquery";
import { Code } from "@connectrpc/connect";
import { useState } from "react";
import { FaEyeSlash, FaRegUser } from "react-icons/fa";
import { IoLockOpenOutline } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { RoleType } from "proto/base/v1/role_pb";

const steps = [
  { title: "Welcome!", description: "Register and start exploring the app!" },
  { title: "OTP", description: "Please enter the OTP sent on your email" },
  { title: "Setup Account", description: "Please enter you basic details" },
];

const StartRegistration = ({
  nextStep,
}: {
  nextStep: (registrationId: string) => void;
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<{ email: string }>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const toast = useToast();

  const { mutate, isPending } = useMutation(startRegistration, {
    onSuccess: (data) => {
      nextStep(data.registrationId);
    },
    onError: (err) => {
      toast({
        title:
          err.code === Code.FailedPrecondition
            ? "An account with this email exists"
            : "Error while creating an Account",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });

      reset();
    },
  });

  const onSubmit = ({ email }: { email: string }) => {
    mutate({ email, role: RoleType.USER });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.email}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <EmailIcon color="gray.500" />
          </InputLeftElement>
          <Input
            type="email"
            placeholder="Email"
            variant={"filled"}
            {...register("email", {
              required: "Required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid Email",
              },
            })}
            isDisabled={isSubmitting || isPending}
          />
        </InputGroup>

        <FormErrorMessage>
          {errors?.email && errors?.email?.message}
        </FormErrorMessage>
      </FormControl>
      <Box h={8} />
      <Button type="submit" w="full" isLoading={isSubmitting || isPending}>
        Continue
      </Button>
      <Flex justify={"center"} pt={16}>
        <Text fontSize={"medium"}>
          Already have an account?
          <Link as={RouterLink} to={"/"} color={"blue.500"} pl={1}>
            Login
          </Link>
        </Text>
      </Flex>
    </form>
  );
};

const VerifyRegistrationOTP = ({
  registrationId,
  nextStep,
}: {
  registrationId: string;
  nextStep: (otp: string) => void;
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
  } = useForm<{ otp: string }>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const toast = useToast();

  const { mutate, isPending } = useMutation(verifyRegistrationOTP, {
    onSuccess: () => {
      nextStep(getValues("otp"));
    },
    onError: (err) => {
      toast({
        title: "Invalid OTP!",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });

      reset({ otp: "" });
    },
  });

  const { mutate: resendRegistration, isPending: resendRegistrationPending } =
    useMutation(resendRegistrationOTP, {
      onSuccess: () => {
        toast({
          title: "OTP has bent resent!",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      },
      onError: (err) => {
        toast({
          title: "Unable to resend OTP!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      },
    });

  const onSubmit = ({ otp }: { otp: string }) => {
    mutate({ otp, registrationId });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <Center>
          <Controller
            control={control}
            name="otp"
            render={({ field }) => (
              <HStack>
                <PinInput
                  {...field}
                  size={"lg"}
                  isDisabled={isSubmitting || isPending}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            )}
          ></Controller>
        </Center>
      </FormControl>
      <Box h={8} />
      <Center>
        <Button
          variant={"ghost"}
          color={"blue.500"}
          onClick={() => {
            resendRegistration({ registrationId });
          }}
          isLoading={resendRegistrationPending}
        >
          Send again
        </Button>
      </Center>
      <Box h={8} />
      <Button type="submit" w="full" isLoading={isSubmitting || isPending}>
        Continue
      </Button>
    </form>
  );
};

const CompleteRegistration = ({
  registrationId,
  otp,
}: {
  registrationId: string;
  otp: string;
}) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
  } = useForm<{
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    mobile: string;
  }>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { mutate, isPending } = useMutation(completeRegistration, {
    onSuccess: (data) => {
      toast({
        title: "Your account has been created!",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      window.location.replace("/");
    },
    onError: (err) => {
      toast({
        title: "Error while creating an Account",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const onSubmit = (data: {
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
  }) => {
    mutate({ ...data, registrationId, otp });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.firstName}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={FaRegUser} color="gray.500" />
          </InputLeftElement>
          <Input
            placeholder="First Name"
            variant={"filled"}
            {...register("firstName", {
              required: "Required",
            })}
            isDisabled={isSubmitting || isPending}
          />
        </InputGroup>

        <FormErrorMessage>
          {errors?.firstName && errors?.firstName?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.lastName}>
        <InputGroup mt={4}>
          <InputLeftElement pointerEvents="none">
            <Icon as={FaRegUser} color="gray.500" />
          </InputLeftElement>
          <Input
            placeholder="Last Name"
            variant={"filled"}
            {...register("lastName", {
              required: "Required",
            })}
            isDisabled={isSubmitting || isPending}
          />
        </InputGroup>

        <FormErrorMessage>
          {errors?.lastName && errors?.lastName?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.mobile}>
        <InputGroup mt={4}>
          <InputLeftElement pointerEvents="none">
            <PhoneIcon color="gray.500" />
          </InputLeftElement>
          <Input
            type="number"
            placeholder="Mobile"
            variant={"filled"}
            {...register("mobile", {
              required: "Required",
              validate: (v) => (v.length === 10 ? true : "Invalid number"),
            })}
            isDisabled={isSubmitting || isPending}
          />
        </InputGroup>

        <FormErrorMessage>
          {errors?.mobile && errors?.mobile?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.password}>
        <InputGroup mt={4}>
          <InputLeftElement pointerEvents="none">
            <IoLockOpenOutline color="gray.500" />
          </InputLeftElement>
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            variant={"filled"}
            {...register("password", {
              required: "Required",
              validate: (v) =>
                v.length >= 8 ? true : "Min 8 characters required",
            })}
            isDisabled={isSubmitting || isPending}
          />
          <InputRightElement>
            <Icon
              onClick={() => setShowPassword(!showPassword)}
              as={showPassword ? FaEyeSlash : MdOutlineRemoveRedEye}
              color="gray.500"
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {errors?.password && errors?.password?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.confirmPassword}>
        <InputGroup mt={4}>
          <InputLeftElement pointerEvents="none">
            <IoLockOpenOutline color="gray.500" />
          </InputLeftElement>
          <Input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            variant={"filled"}
            {...register("confirmPassword", {
              required: "Required",
              validate: (v) =>
                getValues("password") === v ? true : "Passwords do not match",
            })}
            isDisabled={isSubmitting || isPending}
          />
          <InputRightElement>
            <Icon
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              as={showConfirmPassword ? FaEyeSlash : MdOutlineRemoveRedEye}
              color="gray.500"
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {errors?.confirmPassword && errors?.confirmPassword?.message}
        </FormErrorMessage>
      </FormControl>

      <Box h={8} />
      <Button type="submit" w="full" isLoading={isSubmitting || isPending}>
        Continue
      </Button>
    </form>
  );
};

export default function Register() {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 3,
  });
  const [registrationId, setRegistrationId] = useState("");
  const [otp, setOtp] = useState("");

  const renderComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <StartRegistration
            nextStep={(registrationId: string) => {
              setRegistrationId(registrationId);
              setActiveStep(1);
            }}
          />
        );

      case 1:
        return (
          <VerifyRegistrationOTP
            registrationId={registrationId}
            nextStep={(otp: string) => {
              setOtp(otp);
              setActiveStep(2);
            }}
          />
        );

      case 2:
        return (
          <CompleteRegistration registrationId={registrationId} otp={otp} />
        );

      default:
        break;
    }
    return null;
  };

  return (
    <Box p={4} pt={8} m={2}>
      <SimpleGrid columns={3} gap={3} pb={8}>
        {steps.map((_, index) => {
          return (
            <Box
              h={"6px"}
              w={"100%"}
              bg={index <= activeStep ? "blue.500" : "gray.300"}
              rounded={"lg"}
            />
          );
        })}
      </SimpleGrid>
      <Text fontSize={"3xl"} fontWeight={"bold"}>
        {steps[activeStep].title}
      </Text>
      <Text fontSize={"lg"} color={"gray.500"} pb={12}>
        {steps[activeStep].description}
      </Text>
      {renderComponent()}
    </Box>
  );
}
