// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file room/v1/room_api.proto (package room.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddRoomUserRequest, AddRoomUserResponse, CreateRoomRequest, CreateRoomResponse, DeleteRoomRequest, DeleteRoomResponse, DeleteRoomUserRequest, DeleteRoomUserResponse, GetAffiliateRoomRequest, GetAffiliateRoomResponse, GetRoomRequest, GetRoomResponse, ListAffiliateRoomsRequest, ListAffiliateRoomsResponse, ListInvitedRoomsRequest, ListInvitedRoomsResponse, ListRoomsRequest, ListRoomsResponse, UpdateUserRoomPermissionRequest, UpdateUserRoomPermissionResponse } from "./room_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * RoomAPI service
 *
 * @generated from service room.v1.RoomAPI
 */
export const RoomAPI = {
  typeName: "room.v1.RoomAPI",
  methods: {
    /**
     * @generated from rpc room.v1.RoomAPI.CreateRoom
     */
    createRoom: {
      name: "CreateRoom",
      I: CreateRoomRequest,
      O: CreateRoomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.ListRooms
     */
    listRooms: {
      name: "ListRooms",
      I: ListRoomsRequest,
      O: ListRoomsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.ListAffiliateRooms
     */
    listAffiliateRooms: {
      name: "ListAffiliateRooms",
      I: ListAffiliateRoomsRequest,
      O: ListAffiliateRoomsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.ListInvitedRooms
     */
    listInvitedRooms: {
      name: "ListInvitedRooms",
      I: ListInvitedRoomsRequest,
      O: ListInvitedRoomsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.GetRoom
     */
    getRoom: {
      name: "GetRoom",
      I: GetRoomRequest,
      O: GetRoomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.GetAffiliateRoom
     */
    getAffiliateRoom: {
      name: "GetAffiliateRoom",
      I: GetAffiliateRoomRequest,
      O: GetAffiliateRoomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.AddRoomUser
     */
    addRoomUser: {
      name: "AddRoomUser",
      I: AddRoomUserRequest,
      O: AddRoomUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.DeleteRoomUser
     */
    deleteRoomUser: {
      name: "DeleteRoomUser",
      I: DeleteRoomUserRequest,
      O: DeleteRoomUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.DeleteRoom
     */
    deleteRoom: {
      name: "DeleteRoom",
      I: DeleteRoomRequest,
      O: DeleteRoomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc room.v1.RoomAPI.UpdateUserRoomPermission
     */
    updateUserRoomPermission: {
      name: "UpdateUserRoomPermission",
      I: UpdateUserRoomPermissionRequest,
      O: UpdateUserRoomPermissionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

