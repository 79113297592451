// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file aggregate/v1/aggregate_api.proto (package aggregate.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetBoardAggregateRoomsRequest, GetBoardAggregateRoomsResponse, GetUserAggregateProfileRequest, GetUserAggregateProfileResponse } from "./aggregate_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * AggregateAPI service
 *
 * @generated from service aggregate.v1.AggregateAPI
 */
export const AggregateAPI = {
  typeName: "aggregate.v1.AggregateAPI",
  methods: {
    /**
     * @generated from rpc aggregate.v1.AggregateAPI.GetUserAggregateProfile
     */
    getUserAggregateProfile: {
      name: "GetUserAggregateProfile",
      I: GetUserAggregateProfileRequest,
      O: GetUserAggregateProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aggregate.v1.AggregateAPI.GetBoardAggregateRooms
     */
    getBoardAggregateRooms: {
      name: "GetBoardAggregateRooms",
      I: GetBoardAggregateRoomsRequest,
      O: GetBoardAggregateRoomsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

