// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file whiteboard/board/v1/board.proto (package whiteboard.board.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Orientation, Preset, Sport } from "../../../base/v1/whiteboard_pb.js";
import { AccessType } from "../../../base/v1/access_pb.js";

/**
 * @generated from message whiteboard.board.v1.Board
 */
export const Board = /*@__PURE__*/ proto3.makeMessageType(
  "whiteboard.board.v1.Board",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "preview_image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "bg_image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "preset", kind: "enum", T: proto3.getEnumType(Preset) },
    { no: 8, name: "orientation", kind: "enum", T: proto3.getEnumType(Orientation) },
    { no: 9, name: "sport", kind: "enum", T: proto3.getEnumType(Sport) },
    { no: 10, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "created_at", kind: "message", T: Timestamp },
    { no: 13, name: "updated_at", kind: "message", T: Timestamp },
    { no: 14, name: "access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
    { no: 15, name: "editing_user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "original_access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
  ],
);

/**
 * @generated from message whiteboard.board.v1.ListBoard
 */
export const ListBoard = /*@__PURE__*/ proto3.makeMessageType(
  "whiteboard.board.v1.ListBoard",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "preview_image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "preset", kind: "enum", T: proto3.getEnumType(Preset) },
    { no: 6, name: "orientation", kind: "enum", T: proto3.getEnumType(Orientation) },
    { no: 7, name: "sport", kind: "enum", T: proto3.getEnumType(Sport) },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "created_at", kind: "message", T: Timestamp },
    { no: 11, name: "updated_at", kind: "message", T: Timestamp },
    { no: 12, name: "access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
  ],
);

