import { useCallback, useEffect, useState } from "react";
import constate from "constate";
import { useLocalStorage } from "./useLocalStorage";
import { getUserAggregateProfile } from "proto/aggregate/v1/aggregate_api-AggregateAPI_connectquery";
import { useQuery } from "@connectrpc/connect-query";
import { Timestamp } from "@bufbuild/protobuf";

const useAggProfile = (notificationCreatedOnAfter?: Timestamp) => {
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    getUserAggregateProfile,
    { notificationCreatedOnAfter }
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const useGlobalState = () => {
  const [lastNotificationDate, setLastNotificationDate] = useLocalStorage<
    Timestamp | undefined
  >("reticulo:whiteboard:lastNotificationDate", new Timestamp());
  const [loaded, setLoaded] = useState(false);
  const { data, error, isError, isLoading, refetch } =
    useAggProfile(lastNotificationDate);

  // we will only show loading for the time we fetch data
  useEffect(() => {
    if (loaded || isLoading) return;
    setLoaded(true);
  }, [loaded, isLoading]);

  const updateLastNotificationDate = useCallback(() => {
    setLastNotificationDate(data?.lastNotificationDate);
  }, [data?.lastNotificationDate, setLastNotificationDate]);

  return {
    data,
    error,
    isError,
    loaded,
    isFetching: isLoading,
    refetch,
    updateLastNotificationDate,
  };
};

const [GlobalStateProvider, useGlobalStateContext] = constate(useGlobalState);

export { GlobalStateProvider, useGlobalStateContext };
