// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/sort.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.SortDirection
 */
export const SortDirection = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.SortDirection",
  [
    {no: 0, name: "SORT_DIRECTION_INVALID", localName: "INVALID"},
    {no: 1, name: "SORT_DIRECTION_ASCENDING", localName: "ASCENDING"},
    {no: 2, name: "SORT_DIRECTION_DESCENDING", localName: "DESCENDING"},
  ],
);

/**
 * @generated from message base.v1.Page
 */
export const Page = /*@__PURE__*/ proto3.makeMessageType(
  "base.v1.Page",
  () => [
    { no: 1, name: "no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ],
);

