// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file whiteboard/board/v1/board_api.proto (package whiteboard.board.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AcquireBoardLockRequest, AcquireBoardLockResponse, CreateBoardRequest, CreateBoardResponse, DeleteBoardRequest, DeleteBoardResponse, GetBoardRequest, GetBoardResponse, GetBoardRoomsRequest, GetBoardRoomsResponse, ListBoardsRequest, ListBoardsResponse, ReleaseBoardLockRequest, ReleaseBoardLockResponse, UpdateBoardDataRequest, UpdateBoardDataResponse, UpdateBoardRoomsRequest, UpdateBoardRoomsResponse } from "./board_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * BoardAPI service
 *
 * @generated from service whiteboard.board.v1.BoardAPI
 */
export const BoardAPI = {
  typeName: "whiteboard.board.v1.BoardAPI",
  methods: {
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.ListBoards
     */
    listBoards: {
      name: "ListBoards",
      I: ListBoardsRequest,
      O: ListBoardsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.GetBoard
     */
    getBoard: {
      name: "GetBoard",
      I: GetBoardRequest,
      O: GetBoardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.CreateBoard
     */
    createBoard: {
      name: "CreateBoard",
      I: CreateBoardRequest,
      O: CreateBoardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.AcquireBoardLock
     */
    acquireBoardLock: {
      name: "AcquireBoardLock",
      I: AcquireBoardLockRequest,
      O: AcquireBoardLockResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.ReleaseBoardLock
     */
    releaseBoardLock: {
      name: "ReleaseBoardLock",
      I: ReleaseBoardLockRequest,
      O: ReleaseBoardLockResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.UpdateBoardData
     */
    updateBoardData: {
      name: "UpdateBoardData",
      I: UpdateBoardDataRequest,
      O: UpdateBoardDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.DeleteBoard
     */
    deleteBoard: {
      name: "DeleteBoard",
      I: DeleteBoardRequest,
      O: DeleteBoardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.GetBoardRooms
     */
    getBoardRooms: {
      name: "GetBoardRooms",
      I: GetBoardRoomsRequest,
      O: GetBoardRoomsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc whiteboard.board.v1.BoardAPI.UpdateBoardRooms
     */
    updateBoardRooms: {
      name: "UpdateBoardRooms",
      I: UpdateBoardRoomsRequest,
      O: UpdateBoardRoomsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

