import {
  Box,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  useToast,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Flex,
  Link,
  useInterval,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { login as loginAPI } from "proto/auth/v1/auth-AuthAPI_connectquery";
import { useAuthContext } from "hooks";
import { useMutation } from "@connectrpc/connect-query";
import { EmailIcon } from "@chakra-ui/icons";
import { IoLockOpenOutline } from "react-icons/io5";
import { useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaEyeSlash } from "react-icons/fa";
import badmintionBgImage from "assets/images/badmintion-bg.jpg";
import tennisBgImage from "assets/images/tennis-bg.jpg";
import basketballBgImage from "assets/images/basketball-bg.jpg";
import footballBgImage from "assets/images/football-bg.jpg";
import tableTennisBgImage from "assets/images/table-tennis-bg.jpg";
import { Link as RouterLink } from "react-router-dom";

type LoginForm = {
  email: string;
  password: string;
};

const bgImages = [
  badmintionBgImage,
  tennisBgImage,
  basketballBgImage,
  footballBgImage,
  tableTennisBgImage,
];

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginForm>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const toast = useToast();
  const { login } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);

  const { mutate } = useMutation(loginAPI, {
    onSuccess: () => {
      toast({
        title: "Logged in!",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      login();
    },
    onError: () => {
      toast({
        title: "Invalid Credentials.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const onSubmit = async (data: LoginForm) => {
    mutate({
      username: data.email,
      password: data.password,
    });
  };
  const [bgImage, setBgImage] = useState(0);
  const changeBgImage = () => {
    setBgImage(bgImage < bgImages.length - 1 ? bgImage + 1 : 0);
  };
  useInterval(changeBgImage, 3000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        bgImage={bgImages[bgImage]}
        bgSize={"contain"}
        h="100vh"
        display={"flex"}
        flexDir={"column"}
        transition={"1s ease-out"}
      >
        <Box h={48} />
        <Box p={8} pt={8} bg="white" roundedTop={"xl"} flexGrow={1}>
          <Text fontSize={"3xl"} fontWeight={"bold"}>
            Welcome back!
          </Text>
          <Text fontSize={"lg"} color={"gray.500"} pb={12}>
            It's great to have you back! Please log in to access your content.
          </Text>

          <FormControl isInvalid={!!errors.email}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EmailIcon color="gray.500" />
              </InputLeftElement>
              <Input
                type="email"
                placeholder="Email"
                variant={"filled"}
                {...register("email", {
                  required: "Required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Invalid Email",
                  },
                })}
                isDisabled={isSubmitting}
              />
            </InputGroup>

            <FormErrorMessage>
              {errors?.email && errors?.email?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.password}>
            <InputGroup mt={4}>
              <InputLeftElement pointerEvents="none">
                <IoLockOpenOutline color="gray.500" />
              </InputLeftElement>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                variant={"filled"}
                {...register("password", {
                  required: "Required",
                })}
                isDisabled={isSubmitting}
              />
              <InputRightElement>
                <Icon
                  onClick={() => setShowPassword(!showPassword)}
                  as={showPassword ? FaEyeSlash : MdOutlineRemoveRedEye}
                  color="gray.500"
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>
              {errors?.password && errors?.password?.message}
            </FormErrorMessage>
          </FormControl>
          <Box h={4} />
          <Flex justify={"end"}>
            <Link as={RouterLink} to={"/forget-password"}>
              Forgot your password?
            </Link>
          </Flex>
          <Box h={8} />
          <Button type="submit" w="full" isLoading={isSubmitting}>
            Sign in
          </Button>

          <Flex justify={"center"} pt={16}>
            <Text fontSize={"medium"}>
              Don't have an account?
              <Link as={RouterLink} to={"/register"} color={"blue.500"} pl={1}>
                Register
              </Link>
            </Text>
          </Flex>
        </Box>
      </Box>
    </form>
  );
}
