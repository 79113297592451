// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file chat/v1/chat.proto (package chat.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";

/**
 * @generated from message chat.v1.Chat
 */
export const Chat = /*@__PURE__*/ proto3.makeMessageType(
  "chat.v1.Chat",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from_user", kind: "message", T: User },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

