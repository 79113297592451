// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file setting/v1/setting_api.proto (package setting.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetBoardSettingRequest, GetBoardSettingResponse, UpdateBoardSettingRequest, UpdateBoardSettingResponse } from "./setting_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * SettingAPI service
 *
 * @generated from service setting.v1.SettingAPI
 */
export const SettingAPI = {
  typeName: "setting.v1.SettingAPI",
  methods: {
    /**
     * @generated from rpc setting.v1.SettingAPI.GetBoardSetting
     */
    getBoardSetting: {
      name: "GetBoardSetting",
      I: GetBoardSettingRequest,
      O: GetBoardSettingResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc setting.v1.SettingAPI.UpdateBoardSetting
     */
    updateBoardSetting: {
      name: "UpdateBoardSetting",
      I: UpdateBoardSettingRequest,
      O: UpdateBoardSettingResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

