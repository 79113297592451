// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/whiteboard.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.Preset
 */
export const Preset = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.Preset",
  [
    {no: 0, name: "PRESET_INVALID"},
    {no: 1, name: "PRESET_2D"},
    {no: 2, name: "PRESET_SIDE"},
    {no: 3, name: "PRESET_SIDE_FLAT"},
    {no: 4, name: "PRESET_FRONT"},
    {no: 5, name: "PRESET_FRONT_FLAT"},
    {no: 6, name: "PRESET_2D_AO_COURT"},
    {no: 7, name: "PRESET_2D_ROLAND_GARROS"},
    {no: 8, name: "PRESET_2D_US_OPEN_COURT"},
    {no: 9, name: "PRESET_2D_WIMBLEDON"},
    {no: 10, name: "PRESET_3D_BROADCAST_AO"},
    {no: 11, name: "PRESET_3D_BROADCAST_RG"},
    {no: 12, name: "PRESET_3D_BROADCAST_US"},
    {no: 13, name: "PRESET_3D_BROADCAST_WI"},
    {no: 14, name: "PRESET_3D_SIDE_AO"},
    {no: 15, name: "PRESET_3D_SIDE_RG"},
    {no: 16, name: "PRESET_3D_SIDE_US"},
    {no: 17, name: "PRESET_3D_SIDE_WI"},
  ],
);

/**
 * @generated from enum base.v1.Orientation
 */
export const Orientation = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.Orientation",
  [
    {no: 0, name: "ORIENTATION_INVALID", localName: "INVALID"},
    {no: 1, name: "ORIENTATION_LANDSCAPE", localName: "LANDSCAPE"},
    {no: 2, name: "ORIENTATION_PORTRAIT", localName: "PORTRAIT"},
    {no: 3, name: "ORIENTATION_NEUTRAL", localName: "NEUTRAL"},
  ],
);

/**
 * @generated from enum base.v1.Sport
 */
export const Sport = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.Sport",
  [
    {no: 0, name: "SPORT_INVALID", localName: "INVALID"},
    {no: 1, name: "SPORT_BADMINTION", localName: "BADMINTION"},
    {no: 2, name: "SPORT_TENNIS", localName: "TENNIS"},
    {no: 3, name: "SPORT_TABLE_TENNIS", localName: "TABLE_TENNIS"},
    {no: 4, name: "SPORT_BASKETBALL", localName: "BASKETBALL"},
    {no: 5, name: "SPORT_FOOTBALL", localName: "FOOTBALL"},
  ],
);

