import { ChakraProvider } from "@chakra-ui/react";

import popoverTheme from "theme/PopoverTheme";
import { TransportProvider } from "@connectrpc/connect-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Main } from "App/Main";
import { transport } from "clients";
import { defaultOptions } from "@connectrpc/connect-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...defaultOptions.queries,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <ChakraProvider theme={popoverTheme}>
      <TransportProvider transport={transport}>
        <QueryClientProvider client={queryClient}>
          <Main />
        </QueryClientProvider>
      </TransportProvider>
    </ChakraProvider>
  );
}
