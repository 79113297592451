// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file notification/v1/notification.proto (package notification.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { NotificationType } from "../../base/v1/notifcation_pb.js";
import { User } from "../../base/v1/user_pb.js";
import { Room } from "../../room/v1/room_pb.js";
import { Board } from "../../whiteboard/board/v1/board_pb.js";

/**
 * @generated from message notification.v1.Notification
 */
export const Notification = /*@__PURE__*/ proto3.makeMessageType(
  "notification.v1.Notification",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "notification_type", kind: "enum", T: proto3.getEnumType(NotificationType) },
    { no: 3, name: "created_on", kind: "message", T: Timestamp },
    { no: 4, name: "from_user", kind: "message", T: User },
    { no: 5, name: "room", kind: "message", T: Room },
    { no: 6, name: "board", kind: "message", T: Board },
  ],
);

