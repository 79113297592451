import { Code, ConnectError, createPromiseClient } from "@connectrpc/connect";
import { useToast } from "@chakra-ui/react";
import { authAPIClient, transportWithoutInterceptors } from "clients";
import constate from "constate";
import { useEffect, useState } from "react";
import { UserAPI } from "proto/user/v1/user_api_connect";

export const userAPIClient = createPromiseClient(
  UserAPI,
  transportWithoutInterceptors
);

const useAuth = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (loading) {
      const verifySession = async () => {
        try {
          await authAPIClient.verifySession({});
          setIsAuthenticated(true);
        } catch (error) {
          if (
            error instanceof ConnectError &&
            error.code === Code.Unauthenticated
          ) {
            if (error.rawMessage === "no token provided") {
            } else if (error.rawMessage === "incorrect session") {
              toast({
                title: "Your session has expired, please login again.",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Error while logging you in",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          } else {
            toast({
              title: "Error while logging you in",
              description: "Please try again ",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        } finally {
          setLoading(false);
        }
      };

      verifySession();
    }
  }, [loading, toast]);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return {
    loading,
    isAuthenticated,
    login,
    logout,
  };
};

const [AuthProvider, useAuthContext] = constate(useAuth);

export { AuthProvider, useAuthContext };
