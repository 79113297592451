// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file setting/v1/setting_api.proto (package setting.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message setting.v1.BoardSetting
 */
export const BoardSetting = /*@__PURE__*/ proto3.makeMessageType(
  "setting.v1.BoardSetting",
  () => [
    { no: 1, name: "user_free_boards", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "promoter_free_boards", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message setting.v1.GetBoardSettingRequest
 */
export const GetBoardSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "setting.v1.GetBoardSettingRequest",
  [],
);

/**
 * @generated from message setting.v1.GetBoardSettingResponse
 */
export const GetBoardSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "setting.v1.GetBoardSettingResponse",
  () => [
    { no: 1, name: "board_setting", kind: "message", T: BoardSetting },
  ],
);

/**
 * @generated from message setting.v1.UpdateBoardSettingRequest
 */
export const UpdateBoardSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "setting.v1.UpdateBoardSettingRequest",
  () => [
    { no: 1, name: "board_setting", kind: "message", T: BoardSetting },
  ],
);

/**
 * @generated from message setting.v1.UpdateBoardSettingResponse
 */
export const UpdateBoardSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "setting.v1.UpdateBoardSettingResponse",
  [],
);

