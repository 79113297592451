import { AccessType } from "proto/base/v1/access_pb";
import { NotificationType } from "proto/base/v1/notifcation_pb";
import { ResourceType } from "proto/base/v1/resource_pb";
import { User } from "proto/base/v1/user_pb";
import { Orientation, Preset } from "proto/base/v1/whiteboard_pb";

export const protoUserName = (user?: User): string => {
  return `${user?.firstName} ${user?.lastName}`;
};

export const protoAccessTypeColor = (accessType: AccessType): string => {
  switch (accessType) {
    case AccessType.EDITOR:
      return "blue";
    case AccessType.VIEWER:
      return "green";
    case AccessType.OWNER:
      return "red";
    default:
      break;
  }
  return "";
};

export const formatProtoAccessType = (accessType?: AccessType): string => {
  switch (accessType) {
    case AccessType.OWNER:
      return "Owner";
    case AccessType.EDITOR:
      return "Editor";
    case AccessType.VIEWER:
      return "Viewer";
  }
  return "";
};

export const textToProtoAccessType = (s: string): AccessType => {
  switch (s.toLowerCase()) {
    case formatProtoAccessType(AccessType.OWNER).toLowerCase():
      return AccessType.OWNER;
    case formatProtoAccessType(AccessType.EDITOR).toLowerCase():
      return AccessType.EDITOR;
    case formatProtoAccessType(AccessType.VIEWER).toLowerCase():
      return AccessType.VIEWER;
    default:
      break;
  }
  return AccessType.INVALID;
};

export const formatProtoResourceType = (v: ResourceType): string => {
  switch (v) {
    case ResourceType.ROOM:
      return "Room";
    case ResourceType.BOARD:
      return "Board";
    default:
      break;
  }
  return "";
};

export const textToProtoResourceType = (s: string): ResourceType => {
  switch (s.toLowerCase()) {
    case formatProtoResourceType(ResourceType.ROOM).toLowerCase():
      return ResourceType.ROOM;
    case formatProtoResourceType(ResourceType.BOARD).toLowerCase():
      return ResourceType.BOARD;
    default:
      break;
  }
  return ResourceType.INVALID;
};

export const areStringsEq = (s1?: string, s2?: string): boolean => {
  if (!s1 && !s2) return true;
  if (!s1 || !s2) return false;

  s1 = s1.trim();
  s2 = s2.trim();
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  return s1 === s2;
};

export const isStringBlank = (s?: string): boolean => {
  if (!s) return true;
  s = s.trim();
  return s.length < 1;
};

export const stringFirstLetter = (s?: string): string => {
  if (isStringBlank(s)) return "";

  return s![0];
};

export const formatPreset = (p: Preset = Preset.PRESET_2D) => {
  switch (p) {
    case Preset.PRESET_2D:
      return "Flat";
    case Preset.PRESET_SIDE:
      return "Side";
    case Preset.PRESET_SIDE_FLAT:
      return "Side_Flat";
    case Preset.PRESET_FRONT:
      return "Front";
    case Preset.PRESET_FRONT_FLAT:
      return "Front_Flat";

    case Preset.PRESET_2D_AO_COURT:
      return "2D_AO";
    case Preset.PRESET_2D_ROLAND_GARROS:
      return "2D_RG";
    case Preset.PRESET_2D_US_OPEN_COURT:
      return "2D_US";
    case Preset.PRESET_2D_WIMBLEDON:
      return "2D_WI";

    case Preset.PRESET_3D_BROADCAST_AO:
      return "3D_B_AO";
    case Preset.PRESET_3D_BROADCAST_RG:
      return "3D_B_RG";
    case Preset.PRESET_3D_BROADCAST_US:
      return "3D_B_US";
    case Preset.PRESET_3D_BROADCAST_WI:
      return "3D_B_WI";

    case Preset.PRESET_3D_SIDE_AO:
      return "3D_S_AO";
    case Preset.PRESET_3D_SIDE_RG:
      return "3D_S_RG";
    case Preset.PRESET_3D_SIDE_US:
      return "3D_S_US";
    case Preset.PRESET_3D_SIDE_WI:
      return "3D_S_WI";
  }

  return "";
};

export const formatOrientation = (o: Orientation = Orientation.LANDSCAPE) => {
  switch (o) {
    case Orientation.LANDSCAPE:
      return "Landscape";
    case Orientation.PORTRAIT:
      return "Portrait";
    case Orientation.NEUTRAL:
      return "Neutral";
  }

  return "";
};

export const orientationColor = (o: Orientation = Orientation.LANDSCAPE) => {
  switch (o) {
    case Orientation.LANDSCAPE:
      return "red";
    case Orientation.PORTRAIT:
      return "green";
    case Orientation.NEUTRAL:
      return "blue";
  }

  return "";
};

export const loadScript = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const notificationTypeToMessage = (nt: NotificationType): string => {
  switch (nt) {
    case NotificationType.ADDED_TO_ROOM:
      return "Added to room";
    case NotificationType.REMOVED_FROM_ROOM:
      return "Removed from room";
    case NotificationType.BOARD_ADDED_TO_ROOM:
      return "Board added to room";
  }
  return "";
};
